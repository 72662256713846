import React from 'react';
import Catalogues from '../components/product/Catalogues';
import CookingWellness from '../components/CookingWellness';
import HeadMeta from '../components/HeadMeta';
import Promotion from '../components/product/Promotion';
import News from '../components/material/News';
import Actions from '../components/product/Actions';
import Notes from '../components/material/Notes';

function HomePage() {
  return (
    <>
      <HeadMeta
        title='Oriflame Boutique | Сайт независимого партнера Oriflame: Косметика, Красота, Здоровье'
        description='Высококачественная безопасная косметика, ароматы известных парфюмеров, здоровое питание, витамины.'
        keywords='каталог Oriflame, продукция Орифлейм'
      />

      <main className='content'>
        <div className='row g-5'>
          <div className='col-md-6'>
            <Promotion />
          </div>
          <div className='col-md-6'>
            <Notes />
          </div>
        </div>
        <div className='divider'></div>
        <Actions />
        <div className='divider'></div>
        <Catalogues />
        <div className='divider'></div>
        <div className='row row-cols-1 row-cols-md-2 g-4'>
          <div className='col'>
            <News />
          </div>
          <div className='col'>
            <CookingWellness />
          </div>
        </div>

        <div className='divider'></div>
        <div className='text-center mb-5'>
          {/* <img
            className='img-100'
            src='/images/about/standarts.jpg'
            alt='Стандарты, которым ты можешь доверяешь'
          /> */}
        </div>
      </main>
    </>
  );
}

export default HomePage;
