//import axios from 'axios';
import axios from '../api/axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Product from '../components/product/Product';
import { IProduct } from '../models/models';
import token from '../api/token';
import Loader from '../components/Loader';

import HeadMeta from '../components/HeadMeta';
import Breadcrumbs from '../components/Breadcrumbs';
import { categories } from '../data/categories';

//import ProductsFilter from '../components/ProductsFilter';
const ProductsFilter = React.lazy(
  () => import('../components/product/ProductsFilter')
);

function ProductsPage() {
  const [loading, setLoading] = useState(true);
  const [loadedProducts, setLoadedProducts] = useState<IProduct[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);

  const { category = 'scincare' } = useParams();

  const categoryObj = categories.filter((cat) => cat.alias === category)?.[0];

  useEffect(() => {
    async function fetchProducts() {
      try {
        const { data } = await axios.get<IProduct[]>(
          `/products?category=${category}&jwt=${token()}`
        );

        setLoadedProducts(data);
        setProducts(data);

        setLoading(false);
        //console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    fetchProducts();
  }, [category]);

  function productsFilterHandler(value: string) {
    return setProducts(
      loadedProducts.filter((item) =>
        item.id.toLocaleString().startsWith(value)
      )
    );
  }

  return (
    <div>
      <HeadMeta
        title={categoryObj.name ?? `Орифлейм средства категории ${category}`}
        description={
          categoryObj.description ?? `Орифлейм средства категории ${category}`
        }
        pageUrl={`/products/${category}`}
      />
      <Breadcrumbs
        breadcrumbs={[{ title: categoryObj.name ?? 'лицо', link: '' }]}
      />

      <React.Suspense fallback={<p>Loading...</p>}>
        <div>Быстрый поик в категории</div>
        <ProductsFilter onFilter={productsFilterHandler} />
        <br />
      </React.Suspense>

      {loading && <Loader />}

      {products?.length ? (
        <>
          <div className='text-center text-black-50 pb-3'>
            Всего: {products?.length}
          </div>

          <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4'>
            {products.map((product) => (
              <Product
                category={category}
                product={product}
                key={`product-card-${product.id}`}
                items={products?.length ?? 0}
              />
            ))}
          </div>
        </>
      ) : loading ? null : (
        <div>No products...</div>
      )}
    </div>
  );
}

export default ProductsPage;
