import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import HeaderData from '../HeadMeta';
import { INews } from '../../models/models';
import { useParams } from 'react-router-dom';
import axios from '../../api/axios';
import token from '../../api/token';
import Breadcrumbs from '../Breadcrumbs';

function Material() {
  const [article, setArticle] = useState<INews>();
  const { id } = useParams();

  const breadcrumbs = [{ title: 'Статьи', link: '/articles' }];

  useEffect(() => {
    async function fetchMaterials() {
      try {
        const { data } = await axios.get<INews>(
          `/news?type=4&id=${id}&jwt=${token()}`
        );

        setArticle(data);
        console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    fetchMaterials();
  }, [id]);

  return (
    //   {article ? (

    //     {/* <Materials article={article} /> */}
    //   </div>
    //   ):(<Loader />)
    // }

    <div>
      <HeaderData
        title='Полезные статьи'
        description='Полезные статьи'
        pageUrl={`/articles/${id}`}
      />

      <Breadcrumbs
        breadcrumbs={[
          ...breadcrumbs,
          { title: article?.title ?? '', link: '' },
        ]}
      />
      <h2 className='block-header mb-3 text-green'>{article?.title}</h2>
      <p>
        <strong>{article?.spoiler}</strong>
      </p>
      {article?.info ? (
        <div
          className='product-text'
          dangerouslySetInnerHTML={{ __html: article.info }}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Material;
