import React, { useEffect, useState } from 'react';
import { REFSTORE } from '../../data/data';
import { Figure } from 'react-bootstrap';
import axios from '../../api/axios';
import { ICatalogue } from '../../models/models';
import token from '../../api/token';

function Catalogues() {
  const [catalogueItems, setCatalogues] = useState<ICatalogue[]>([]);

  useEffect(() => {
    async function fetchCatalogues() {
      try {
        const { data } = await axios.get<ICatalogue[]>(
          `/catalogues?jwt=${token()}`
        );

        setCatalogues(data);
        //console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    fetchCatalogues();
  }, []);

  return (
    <aside>
      <h2 className='block-header mb-4 text-green'>Онлайн-версии каталогов</h2>
      <div className='row g-5'>
        {catalogueItems.map((catalogue: ICatalogue) => (
          <div className='col-md-3' key={`catalogue-${catalogue.id}`}>
            <a
              href={`${catalogue.link}&${REFSTORE}`}
              target='_blank'
              rel='noreferrer'>
              <Figure>
                <Figure.Image
                  width={171}
                  height={180}
                  className='w-100 shadow'
                  alt={`Каталог Oriflame ${catalogue.nomer} | ${catalogue.info}`}
                  title={`Каталог Oriflame ${catalogue.nomer} | ${catalogue.info}`}
                  src={catalogue.img}
                  loading='lazy'
                />
                <Figure.Caption className='text-center'>
                  Каталог {catalogue.nomer}
                  <br />
                  {catalogue.period}
                </Figure.Caption>
              </Figure>
            </a>
          </div>
        ))}
      </div>
    </aside>
  );
}

export default Catalogues;
