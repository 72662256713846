import { ICategory } from '../models/models';

export const categories: ICategory[] = [
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597608',
    id: 1,
    pid: undefined,
    menu: 'main',
    alias: 'skincare',
    name: 'Лицо',
    title:
      'Эффективные средства с натуральными ингредиентами для ухода за лицом от Орифлейм: очищение, увлажнение, средства направленного действия',
    description: '',
    sort: 1,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597601',
    id: 2,
    pid: 1,
    menu: 'main',
    alias: 'makeup',
    name: 'Макияж',
    title: 'Макияж',
    description: '',
    sort: 2,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597602',
    id: 3,
    pid: 1,
    menu: 'main',
    alias: 'fragrance',
    name: 'Ароматы',
    title: 'Ароматы',
    description: '',
    sort: 3,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597603',
    id: 4,
    pid: 1,
    menu: 'main',
    alias: 'bath-body',
    name: 'Для тела',
    title: 'Каталог',
    description: '',
    sort: 4,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597604',
    id: 5,
    pid: 1,
    menu: 'main',
    alias: 'hair',
    name: 'Для волос',
    title: 'Для волос',
    description: '',
    sort: 5,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597605',
    id: 6,
    pid: 1,
    menu: 'main',
    alias: 'accessories',
    name: 'Аксессуары',
    title: 'Аксессуары',
    description: '',
    sort: 6,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597606',
    id: 7,
    pid: 1,
    menu: 'main',
    alias: 'wellness',
    name: 'Wellness',
    title: 'Wellness',
    description: '',
    sort: 7,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597607',
    id: 8,
    pid: 1,
    menu: 'main',
    alias: 'men',
    name: 'Для мужчин',
    title: 'Для мужчин',
    description: '',
    sort: 8,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597618',
    id: 9,
    pid: 1,
    menu: 'main',
    alias: 'essential-oils',
    name: 'Эфирные масла',
    title: 'Эфирные масла',
    description: '',
    sort: 9,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597609',
    id: 10,
    pid: 1,
    menu: 'main',
    alias: 'kids-baby',
    name: 'Для детей',
    title: 'Для детей',
    description: '',
    sort: 10,
  },
  {
    uid: 'd50fd912-b43c-11ed-8a7b-8cdcd4597610',
    id: 11,
    pid: 1,
    menu: 'main',
    alias: 'new',
    name: 'Новинки',
    title: 'Новинки',
    description: '',
    sort: 11,
  },
];
