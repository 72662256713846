import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function CookingWellness() {
  return (
    <>
      <h2 className='block-header mb-4 text-green'>Готовим вместе Wellness</h2>

      <Carousel indicators={false}>
        {new Array(74).fill('').map((item, i) => (
          <Carousel.Item key={`cookingwellness-page-${(i += 1)}`}>
            <img
              className='d-block w-100 carousel-item-img'
              src={`/images/cookingwellness/page-${(i += 1)}.jpg`}
              alt='Готовим вместе Wellness by Oriflame | только полезные рецепты'
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}

export default CookingWellness;
