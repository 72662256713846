import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { categories } from '../../data/categories';
import { ICategory } from '../../models/models';

function TopNavigation() {
  const [active, setActive] = useState(false);

  const navActiveClassName = active ? 'active' : '';
  const navClasses = ['', navActiveClassName];
  return (
    <div className='bg-secondary'>
      <Nav
        id='navbarProducts'
        className='container'
        activeKey='/skincare'
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      >
        {categories.map((item: ICategory) => (
          <Nav.Item key={item.uid}>
            <Nav.Link
              className={navClasses.join(' ')}
              href={`/products/${item.alias}`}
              title={`${item.name} (${item.alias})`}
              onClick={() => setActive((prev) => !prev)}>
              {item.name}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
}

export default TopNavigation;
