import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { ORIFLAMERU, REFSTORE } from '../../data/data';
import token from '../../api/token';
import { INews } from '../../models/models';

function News() {
  const [news, setNews] = useState<INews[]>([]);

  useEffect(() => {
    async function fetchNews() {
      try {
        const { data } = await axios.get<INews[]>(
          `/news?type=1&jwt=${token()}`
        );

        setNews(data);
        //console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    fetchNews();
  }, []);
  return (
    <>
      <h2 className='block-header mb-3 text-green'>Новости</h2>
      {news.map((item: INews) => (
        <>
          <article className='mb-3' key={`news-${item.id}`}>
            <header>
              <p className='mb-2 fw-semibold text-gray'>{item.title}</p>
            </header>
            <p className='mb-3'>{item.spoiler}</p>
            <a
              className='btn </article>btn-order text-white '
              href={`${item.link}?${REFSTORE}`}
              rel='noreferrer'
              target='_blank'>
              Подробнее
            </a>
          </article>
          <div className='divider my-3'></div>
        </>
      ))}
      <article>
        <header>
          <p className='mb-2 fw-semibold text-gray'>
            Портал Educare: что это и как он поможет твоей коже?
          </p>
        </header>
        <p className='mb-3'>
          Интерактивные тесты, видео с экспертами по антивозрастному уходу и
          урок face fitness — да, это всё там. Учимся раскрывать настоящий
          потенциал своей кожи.
        </p>
        <a
          className='btn btn-order text-white'
          target='_blank'
          rel='noreferrer'
          href={`${ORIFLAMERU}/educare?${REFSTORE}&mindbox-message-key=-4823777411899031552&mindbox-click-id=39b227e6-e6df-4a65-b522-411bbc819f24&utm_source=mindbox&utm_medium=email&utm_campaign=brand_novage_c05_23`}>
          Интересно
        </a>
      </article>
    </>
  );
}

export default News;
