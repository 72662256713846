import React from 'react';
import Head from '../components/HeadMeta';
import Loader from '../components/Loader';

function AboutPage() {
  return (
    <>
      <Head
        title='Продукция Oriflame'
        description='Продукция Oriflame'
        pageUrl='/about'
      />
      <Loader />
    </>
  );
}

export default AboutPage;
