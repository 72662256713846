import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { ORIFLAMERU, REFSTORE } from '../../data/data';
import { IProduct } from '../../models/models';
import ProductModal from './ProductModal';

interface ProductProps {
  product: IProduct;
  category: string;
  items: number;
}

export default function Product({ product, category, items }: ProductProps) {
  var widthStyle = items < 4 ? { minWidth: '259px' } : {};
  return (
    <div className='col'>
      <Card
        className='h-100 shadow-sm'
        itemScope
        itemType='http://schema.org/Product'
        style={widthStyle}>
        <Card.Img
          variant='top'
          src={`../images/products/${category}/${product.id}/0_sm.jpg`}
          alt={product.title}
          title={product.title}
          className='card-img-top'
          loading='lazy'
          itemProp='image'
        />
        {product.isnew && (
          <div className='position-absolute top left p-2'>
            <span className='badge bg-light text-danger'>Новинка</span>
          </div>
        )}

        <Card.Body className='py-0'>
          {product.untilstocklasts && (
            <div className='text-end' style={{ marginTop: '-24px' }}>
              {product.canbesold ? (
                product.untilstocklasts && (
                  <span className='badge bg-light text-green'>
                    Ограниченный выпуск
                  </span>
                )
              ) : (
                <span className='badge bg-light text-danger'>
                  Нет в наличии
                </span>
              )}
            </div>
          )}

          <Card.Text className='my-2' style={{ color: '#999' }}>
            {product.brandName && (
              <small className='pe-3'>{product.brandName}</small>
            )}
            <small>#{product.id}</small>
          </Card.Text>
          <Card.Title
            className='fw-semibold pt-0'
            title={product.title}
            itemProp='name'>
            <small>{product.title}</small>
          </Card.Title>
        </Card.Body>

        <ul className='list-group list-group-flush border-0 mb-3'>
          <li className='list-group-item py-1'>
            <div
              itemProp='offers'
              itemScope
              itemType='http://schema.org/Offer'
              className='d-flex'>
              <div itemProp='price' className='fw-semibold me-2'>
                <span style={{ color: '#D2691E' }}>
                  {`${product.current_price.toLocaleString('ru-RU')},00р.`}
                </span>
              </div>
              <div>
                <small>
                  <s style={{ color: '#999' }}>
                    {product.old_price !== product.current_price &&
                      `${product.old_price?.toLocaleString('ru-RU')},00р.`}
                  </s>
                </small>
              </div>
            </div>
          </li>
        </ul>
        <Card.Footer className='product-card-footer d-flex flex-row justify-content-between pb-3'>
          <ProductModal category={category} product={product} />
          <Button
            title='Купить на официальном сайте Орифлейм'
            className='btn btn-sm btn-order d-flex items-center'
            href={`${ORIFLAMERU}/products/product?code=${product.id}&${REFSTORE}`}
            target='_blank'>
            Купить
          </Button>
        </Card.Footer>
      </Card>
    </div>
  );
}
