import React from 'react';
import { Helmet } from 'react-helmet';

interface HeadMetaProps {
  title: string;
  description: string;
  keywords?: string;
  pageUrl?: string;
}

function HeadMeta({ title, description, keywords, pageUrl }: HeadMetaProps) {
  const canonical = `https://oriflame-boutique.ru${pageUrl}`;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta property='og:url' content='https://oriflame-boutique.ru/' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content='%PUBLIC_URL%/oriflame-boutique.png' />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:image' content='%PUBLIC_URL%/oriflame-boutique.png' />
      <link rel='canonical' href={canonical} />
    </Helmet>
  );
}

export default HeadMeta;
