import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { REFSTORE } from '../../data/data';
import token from '../../api/token';
import { INews } from '../../models/models';
import { Card, Col, Row } from 'react-bootstrap';

function Actions() {
  const [news, setNews] = useState<INews[]>([]);

  useEffect(() => {
    async function fetchCatalogues() {
      try {
        const { data } = await axios.get<INews[]>(
          `/news?type=3&take=4&jwt=${token()}`
        );

        setNews(data);
        //console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    fetchCatalogues();
  }, []);
  return (
    <>
      <h2 className='block-header mb-3 text-green'>
        Специальные предложения для партнеров
      </h2>
      <Row xs={1} md={4} className='g-2'>
        {news.map((item: INews) => (
          <Col key={`news-${item.id}`}>
            <a
              style={{ textDecoration: 'none', color: '#666' }}
              href={`${item.link}?${REFSTORE}`}
              rel='noreferrer'
              target='_blank'>
              <Card className='h-100 shadow'>
                <Card.Img variant='top' src={item.image} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.spoiler}</Card.Text>
                </Card.Body>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Actions;
