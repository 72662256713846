import CryptoJS from 'crypto-js';

export default function token() {
  var secret = 'The secret api key';

  var header = {
    alg: 'HS256',
    typ: 'JWT',
  };

  var data = {
    id: 'react-app',
    iss: 'oriflame-boutique',
    iat: Date.now(),
  };

  let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  let encodedHeader = base64url(stringifiedHeader);

  let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  let encodedData = base64url(stringifiedData);

  let encodedHeaderData = encodedHeader + '.' + encodedData;
  let signatureHash = CryptoJS.HmacSHA256(encodedHeaderData, secret);
  let signature = base64url(signatureHash);

  return `${encodedHeader}.${encodedData}.${signature}`;
}

function base64url(source: any): string {
  // Encode in classical base64
  //encodedSource = CryptoJS.enc.Base64.stringify(source);
  let encodedSource = CryptoJS.enc.Base64.stringify(source);
  // Remove padding equal characters
  encodedSource = encodedSource.replace(/=+$/, '');

  // Replace characters according to base64url specifications
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');

  return encodedSource;
}
