import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IProduct } from '../../models/models';
import Breadcrumbs from '../Breadcrumbs';
import { ORIFLAMERU, REFSTORE } from '../../data/data';
//import { categories } from '../data/categories';

interface ProductModalProps {
  category: string;
  product: IProduct;
}

function ProductModal({ category, product }: ProductModalProps) {
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showPriceBtnClass = details ? 'text-lowercase' : '';
  const showPriceBtnClasses = ['btn btn-more ms-3', showPriceBtnClass];

  const toLocalPrice = (price: number, showRubSign: boolean = false) => {
    const options = {
      style: 'currency',
      currency: 'RUB',
    };

    return (
      price &&
      (showRubSign
        ? Math.floor(price)?.toLocaleString('ru-RU', options)
        : Math.floor(price)?.toLocaleString('ru-RU'))
    );
  };

  return (
    <>
      <Button
        className='btn btn-sm btn-more d-flex items-center'
        onClick={handleShow}>
        Подробнее
      </Button>

      <Modal
        fullscreen
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='modal-fullscreen'>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
          <Breadcrumbs
            breadcrumbs={[
              { title: product.categoryName ?? '', link: product.category },
            ]}
          />
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row g-5'>
              <div className='col-md-4 '>
                <figure className='figure position-sticky top-0 shadow-lg p-3'>
                  <figcaption className='figure-caption'>
                    <h4 className='text-end'>
                      <span
                        className='fw-semibold pe-1'
                        style={{ color: '#D2691E' }}>
                        {toLocalPrice(product.current_price, true)}
                      </span>
                      <br />
                      <s className='text-nowrap pe-1 fw-light'>
                        <small style={{ color: '#999' }}>
                          {product.old_price &&
                          product.old_price !== product.current_price
                            ? toLocalPrice(product.old_price, true)
                            : ''}
                        </small>
                      </s>
                    </h4>
                  </figcaption>
                  <img
                    src={`../images/products/${category}/${product.id}/0_md.jpg`}
                    className='figure-img img-fluid rounded position-sticky top left'
                    alt={product.title}
                  />
                  <figcaption className='figure-caption text-center pb-5'>
                    {product.title}
                  </figcaption>
                </figure>
              </div>
              <div className='col-md-8 ms-auto'>
                <div className='mb-5'>
                  <h5 className='text-grey mb-1'>{product.brandName}</h5>

                  <h3 className='text-green'>
                    {product.title}
                    <span className='text-black-50 fs-5 ps-3'>
                      ( #{product.id} )
                    </span>
                  </h3>
                </div>

                {product.info && (
                  <div
                    className='product-text'
                    dangerouslySetInnerHTML={{ __html: product.info }}
                  />
                )}
                {product.description && (
                  <>
                    <h6 className='text-uppercase py-2 text-grey'>Описание</h6>
                    <div
                      className='product-text'
                      dangerouslySetInnerHTML={{
                        __html: product.description,
                      }}></div>
                  </>
                )}

                {product.details && (
                  <>
                    <h6 className='text-uppercase py-2 text-grey'>
                      Как пользоваться
                    </h6>
                    <div
                      className='product-text'
                      dangerouslySetInnerHTML={{
                        __html: product.details,
                      }}></div>
                  </>
                )}
                {product.ingredients && (
                  <>
                    <h6 className='text-uppercase py-2 text-grey'>Состав</h6>
                    <div
                      className='product-text'
                      dangerouslySetInnerHTML={{
                        __html: product.ingredients,
                      }}></div>
                  </>
                )}

                <h6 className='text-uppercase mt-5 py-2'>
                  {product.brandName}
                </h6>
                {product.brandInfo && (
                  <div className='bg-light p-3 mb-3'>{product.brandInfo}</div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-between'>
          <Button
            className={showPriceBtnClasses.join(' ')}
            onClick={() => setDetails((prev) => !prev)}>
            {details
              ? `${toLocalPrice(product.current_price * 0.8)},00р.`
              : 'Цена для партнеров'}
          </Button>
          <div className='d-flex justify-content-between'>
            <Button
              title='Купить на официальном сайте Орифлейм'
              className='btn btn-sm btn-order d-flex items-center me-3'
              href={`${ORIFLAMERU}/products/product?code=${product.id}&${REFSTORE}`}
              target='_blank'>
              Купить
            </Button>
            <Button className='btn-order me-5' onClick={handleClose}>
              Закрыть
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProductModal;
