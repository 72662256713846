import React, { useEffect, useState } from 'react';
import axios from '../../api/axios';
import { REFSTORE } from '../../data/data';
import token from '../../api/token';
import { INews } from '../../models/models';

function Notes() {
  const [news, setNews] = useState<INews[]>([]);

  useEffect(() => {
    async function fetchCatalogues() {
      try {
        const { data } = await axios.get<INews[]>(
          `/news?type=2&take=5&jwt=${token()}`
        );

        setNews(data);
        //console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    fetchCatalogues();
  }, []);
  return (
    <>
      {/* <h2 className='block-header mb-3 text-green'>Новости</h2> */}
      {news.map((item: INews) => (
        <>
          <article className='mb-3' key={`news-${item.id}`}>
            <header>
              <a
                className='d-block mb-2 fw-semibold text-green mb-2'
                href={`${item.link}?${REFSTORE}`}
                rel='noreferrer'
                target='_blank'
                style={{ textDecoration: 'none' }}>
                &#10003; {item.title}
              </a>
            </header>
            <a
              className='d-block mb-2 text-gray mb-2'
              href={`${item.link}?${REFSTORE}`}
              rel='noreferrer'
              target='_blank'
              style={{ textDecoration: 'none', color: '#666' }}>
              {item.spoiler}
            </a>
          </article>

          <div className='divider my-3'></div>
        </>
      ))}
    </>
  );
}

export default Notes;
