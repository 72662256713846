import React from 'react';
import Loader from '../components/Loader';
import HeadMeta from '../components/HeadMeta';

function NewsPage() {
  return (
    <>
      <HeadMeta title='Новости' description='Новости' pageUrl='/news' />
      <Loader />
    </>
  );
}

export default NewsPage;
