import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

interface SearchProps {
  onSearch: Function;
}

function useInputValue(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);

  return {
    bind: {
      value,
      onChange: (event: any) => setValue(event.target.value),
    },
    clear: () => setValue(''),
    value: () => value,
  };
}

function Search({ onSearch }: SearchProps) {
  const input = useInputValue('');

  //React.ChangeEvent<HTMLInputElement>
  function submitHandler(event: any) {
    event.preventDefault();

    if (input.value().trim()) {
      onSearch(input.value());
      input.clear();
    }
  }

  return (
    <Form className='d-flex' onSubmit={submitHandler}>
      <Form.Control
        type='search'
        placeholder='...'
        className='me-2'
        aria-label='Search'
        {...input.bind}
      />
      <Button variant='outline-success'>Искать</Button>
    </Form>
  );
}

export default Search;
