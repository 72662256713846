import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function Promotion() {
  return (
    <>
      <h2 className='block-header mb-3 text-green'>
        Выгодные предложения каталога
      </h2>

      <Carousel>
        {new Array(30).fill('').map((item, i) => (
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={`/images/promo/${i}.jpg`}
              alt='Выгодные предложения текущего каталога Oriflame'
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}

export default Promotion;
