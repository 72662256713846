import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import TopNavigation from './components/product/ProductNavigation';

import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';
import NewsPage from './pages/NewsPage';
import ProductsPage from './pages/ProductsPage';
import Footer from './components/Footer';
import MaterialsList from './components/material/MaterialsList';
import Material from './components/material/Material';

const App = () => {
  return (
    <div className='app'>
      <header className='app-header'>
        <Header />
        <TopNavigation />
      </header>
      <div className='app-content container pt-3'>
        <Routes>
          {/* topMenu routes */}
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/news' element={<NewsPage />} />

          <Route path='/products/:category' element={<ProductsPage />} />
          <Route
            path='/products'
            element={<Navigate to='/products/skincare' />}
          />
          <Route path='/articles/:id' element={<Material />} />
          <Route path='/articles' element={<MaterialsList />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
