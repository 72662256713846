import React from 'react';
import Breadcrumb from 'react-bootstrap/esm/Breadcrumb';
import { IBreadcrumbs } from '../models/models';

interface BreadcrumbsProps {
  breadcrumbs: IBreadcrumbs[];
}

function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  return (
    <Breadcrumb className='pb-3'>
      <Breadcrumb.Item href='/' key={`breadcrumb-home`}>
        Главная
      </Breadcrumb.Item>
      {breadcrumbs.map((item, i) =>
        breadcrumbs.length === i + 1 ? (
          <Breadcrumb.Item href={`${item.link}`} key={`breadcrumb-${i}`} active>
            {item.title}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item href={`${item.link}`} key={`breadcrumb-${i}`}>
            {item.title}
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
