import axios from 'axios';
import token from '../api/token';

//console.log(token());

export default axios.create({
  baseURL: 'https://api.oriflame-boutique.ru',
  // timeout: 1000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: 'Bearer ' + token(),
  },
  responseType: 'json',
});
