import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import HeaderData from '../HeadMeta';
import { INews } from '../../models/models';
import { Link } from 'react-router-dom';
import axios from '../../api/axios';
import token from '../../api/token';
import { Breadcrumb } from 'react-bootstrap';
import Breadcrumbs from '../Breadcrumbs';

function MaterialsList() {
  const [articles, setArticles] = useState<INews[]>([]);
  const breadcrumbs = [{ title: 'Статьи', link: '/articles' }];

  useEffect(() => {
    async function fetchMaterials() {
      try {
        const { data } = await axios.get<INews[]>(
          `/news?type=4&jwt=${token()}`
        );

        setArticles(data);
        //console.log(data);
      } catch (e) {
        console.error(e);
      }
    }
    fetchMaterials();
  }, []);

  return (
    <div>
      <HeaderData
        title='Полезные статьи'
        description='Полезные статьи'
        pageUrl='/articles'
      />
      {/* <Loader /> */}

      <Breadcrumbs breadcrumbs={breadcrumbs} />

      {articles.map((item: INews) => (
        <article className='mb-3' key={`article-${item.id}`}>
          <header>
            <p className='mb-2 fw-semibold text-gray'>{item.title}</p>
          </header>
          <p className='mb-3'>{item.spoiler}</p>
          <Link
            to={`/articles/${item.id}`}
            className='btn btn-order text-white '>
            Подробнее
          </Link>

          <div className='divider my-3'></div>
        </article>
      ))}
    </div>
  );
}

export default MaterialsList;
